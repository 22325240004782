import '../styles/contact-us-form.css';
import '../styles/menu-mobile.css';
import '../styles/menu.css';

import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Accordion, Card, Modal, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import assurity from '../images/assurity.png';
import call1 from '../images/call-24-px.png';
import giticon from '../images/get-in-touch.svg';
import email1 from '../images/ic-email-24-px.png';
import iconback from '../images/icon-back-black.svg';
import iconorange from '../images/icon-back-orange.svg';
import lglogo from '../images/legal-general.png';
import loginbtn from '../images/locklogin.svg';
import Nationwide from '../images/nationwidelogo.png';
import northamerica from '../images/north-america.png';
import prudential from '../images/prudential.png';
// import Safeco from '../images/safecohome.png';
import SBLI from '../images/sbli-sm.jpg';
import StillWater from '../images/stillwater.jpg';
import transamerica from '../images/transamerica.png';
import wnslogo from '../images/wnslogo.svg';
import HomePal from './HomePal';
import HubSpotContactUsForm from './hubSpotContactUsForm';
import LifePal from './LifePal';
const HDImg = 'https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo.svg';


function MenuMobile( { partner, alt, logo, modalOpen, modalChanged, url } ) {

	const [showArrow, setArrow] = useState( 0 );
	const [showNav, setNav] = useState( false );
	const [showArrowAcc, setArrowAcc] = useState( 0 );

	function clicked( i ) {
		if( showArrow === i ) {
			setArrow( 0 );
		} else {
			setArrow( i );
		}
	}
	function clickedAcc( i ) {
		if( showArrowAcc === i ) {
			setArrowAcc( 0 );
		} else {
			setArrowAcc( i );
		}
	}

	return(
		<Navbar fixed='top' collapseOnSelect expand="lg" bg="light" className="bgcolorbox d-block d-sm-block d-md-block">
			<Navbar.Brand>
				{
					partner ?
						<>
							<Link className="d-flex"
								to="/">
								<img src={HDImg} id="logoBubble" style={{ width: '90px', marginRight: '10px' }} alt="Bubble Insurance"/>
							</Link>

							<a href={url} className="dinline-block">
								<img src={logo} style={{
									width: '45%',
									maxWidth: '200px',
									height: 'auto',
									marginBottom: 0
								}} id="logoBubblePartner" alt={alt}/>
							</a>
						</>
						:
						<Link
							to="/">
							<img src={HDImg} id="logoBubble" alt="Bubble Insurance"/>
						</Link>
				}
			</Navbar.Brand>

			<Navbar.Toggle onClick={() => setNav( !showNav )} aria-controls="basic-navbar-nav">
				{!showNav ?
					<svg width="17" height="12" xmlns="http://www.w3.org/2000/svg">
						<line x1="1" x2="16" y1="2" y2="2" stroke="#2a2727" strokeWidth="2" />

						<line x1="1" x2="16" y1="6" y2="6" stroke="#2a2727" strokeWidth="2" />

						<line x1="1" x2="16" y1="10" y2="10" stroke="#2a2727" strokeWidth="2" />
					</svg>
					:
					<svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
						<line x1="1" x2="14" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />

						<line x1="14" x2="1" y1="1" y2="14" stroke="#2a2727" strokeWidth="2" />
					</svg>
				}
			</Navbar.Toggle>

			<Navbar.Collapse id="responsive-navbar-nav" className="custmobmenu">
				<Nav className="mr-auto">
					<div className="autoscrolldiv">
						<NavDropdown
							onClick={() => clicked( 1 )}
							title={
								<div className="d-flex justify-content-between align-items-center menu-heading">
            About
									<img src={showArrow == 1 ?  iconorange : iconback} alt="icon"/>
								</div>
							} id="collasible-nav-dropdown">
							<NavDropdown.Item>
								<Link to="/about/" className='menu-sub-heading commonheadlink'>
                  Who we are
								</Link>
							</NavDropdown.Item>

							<NavDropdown.Divider className="commonborderbt" />

							<NavDropdown.Item >
								<Link to="/our-story/" className='menu-sub-heading commonheadlink'>
                  Our Story
								</Link>
							</NavDropdown.Item>

							<NavDropdown.Divider className="commonborderbt" />

							<NavDropdown.Item>
								<Link  to="/our-team/" className='menu-sub-heading commonheadlink'>
                  Our Team
								</Link>
							</NavDropdown.Item>

							<NavDropdown.Divider className="commonborderbt" />

							<NavDropdown.Item >
								<a
									onClick={e => {
										e.preventDefault();window.open( 'https://www.getmybubble.com/blog/', '_blank' );
									}} className='menu-sub-heading commonheadlink' rel="noreferrer noopener" target="_blank"  href="https://www.getmybubble.com/blog/">Blog</a>
							</NavDropdown.Item>

							<NavDropdown.Divider className="commonborderbt" />

							<NavDropdown.Item>
								<Link  to="/testimonial/" className='menu-sub-heading commonheadlink'>
                  Testimonial
								</Link>
							</NavDropdown.Item>

							<NavDropdown.Divider className="commonborderbt" />


						</NavDropdown>

						<Accordion className='menu-accordion'>
							<Card>
								<Accordion.Toggle
									onClick={() => clicked( 2 )}
									eventKey='1' as={Card.Header}  className="d-flex justify-content-between align-items-center menu-heading">
                Insurance
									<img src={showArrow == 2 ?  iconorange : iconback} alt="icon"/>
								</Accordion.Toggle>

								<Accordion.Collapse eventKey='1'>
									<Card.Body>
										<Accordion>
											<div className="row">
												<div className="col-md-12 col-12 ">
													<Card>
														<Accordion.Toggle onMouseEnter={() => {
															clickedAcc( 1 );
														}}
														onMouseLeave={
															() => {
																clickedAcc( 1 );
															}} eventKey='1'>
															<Link className='linkName' to="/homeowners-insurance/">
                              Homeowners Insurance
																<img className="show-sm show-all m-0"
																	src={showArrowAcc == 1 ?  iconorange : iconback}
																	tabIndex={0} alt="button" role="button" />
															</Link>
														</Accordion.Toggle>

														<Accordion.Collapse eventKey='1'>
															<Card.Body>

																<ul className="droplist mobdroplist">
																	<li>
																		<Link to="/stillwater/">
																			<img src={StillWater} alt="StillWater logo" />
                                  Stillwater
																		</Link>
																	</li>

																	<li>
																		<Link to="/homeowners-insurance/nationwide/">
																			<img src={Nationwide} alt="logo" />
                                    Nationwide
																		</Link>
																	</li>

																	{/* <li>
																		<Link to="/homeowners-insurance/safeco/">
																			<img src={Safeco} alt="logo" />
                                      Safeco
																		</Link>
																	</li> */}
																</ul>

															</Card.Body>
														</Accordion.Collapse>
													</Card>

													<Card>

														<Accordion.Toggle onMouseEnter={() => {
															clickedAcc( 6 );
														}} onMouseLeave={() => {
															clickedAcc( 6 );
														}} eventKey='2'>
															<Link className='linkName' >
      Life Insurance
																<img className="show-sm show-all m-0"
																	src={showArrowAcc == 2 ?  iconorange : iconback}
																	tabIndex={0} alt="button" role="button" />
															</Link>
														</Accordion.Toggle>


														<Accordion.Collapse eventKey='2'>
															<Card.Body>
																<ul className="droplist mobdroplist">
																	{/* <h3>Who we work with</h3> */}

																	{/* <li>
                                    <Link to="/next-by-pacific-life/">
                                      <img src="https://devuiassetscdn.getmybubble.io/images/next-by-pacific-life-1.png" className="imgBox" alt="pacific life logo" />
          Next Term Life
                                    </Link>
                                  </li> */}

																	<li>
																		<Link to="/SBLI/">
																			<img src={SBLI} alt="logo" />
          SBLI
																		</Link>
																	</li>

																	<li>
																		<Link to="https://cdn.back9ins.com/product-guides/105-LAA2233.pdf" target="_blank">
																			<img src={lglogo} alt="logo" />
          Legal & General
																		</Link>
																	</li>

																	<li>
																		<Link to="https://cdn.back9ins.com/product-guides/441-TransamericaTrendsetterLBBrochure.pdf" target="_blank">
																			<img src={transamerica} alt="logo" />
          Transamerica
																		</Link>
																	</li>

																	<li>
																		<Link to="https://cdn.back9ins.com/product-guides/665-1010826-00002_Term%2BEssential%2BTerm%2BElite%2BConsumer%2BBrochure%2B(1).pdf" target="_blank">
																			<img src={prudential} alt="logo" />
          Prudential Financial
																		</Link>
																	</li>

																	<li>
																		<Link to="https://cdn.back9ins.com/product-guides/550-608NM-1%2BADDvantage%2BTerm%2BConsumer%2BGuide.pdf" target="_blank">
																			<img src={northamerica} alt="logo" />
          North American
																		</Link>
																	</li>

																	<li>
																		<Link to="https://assurity.dmplocal.com/dsc/collateral/Term_Life_Consumer_Brochure.pdf?d03930b82852e26633e6bac5638c4b2c" target="_blank">
																			<img src={assurity} alt="logo" />
          Assurity
																		</Link>
																	</li>

																	<li>
																		<Link to="/wns/">
																			<img src={wnslogo} alt="logo" />
          W&S
																		</Link>
																	</li>
																</ul>
															</Card.Body>
														</Accordion.Collapse>
													</Card>

													<Card>
														<Accordion.Toggle onMouseEnter={() => {

															clickedAcc( 7 );
														}}
														onMouseLeave={() =>{
															clickedAcc( 7 );
														}}
														eventKey='3'>
															<Link className='linkName'>
      Auto Insurance
																<img className="show-sm show-all m-0"
																	src={showArrowAcc == 3 ?  iconorange : iconback}
																	tabIndex={0} alt="button" role="button" />
															</Link>
														</Accordion.Toggle>

														<Accordion.Collapse eventKey='3'>
															<Card.Body>
																<ul className="droplist mobdroplist">
																	{/* <h3>Who we work with</h3> */}

																	<li>
																		<Link to="https://public-bubble.s3.us-west-2.amazonaws.com/images/autoBroch.pdf" target="_blank">
																			<img src={StillWater} alt="StillWater logo" />Stillwater
																		</Link>
																	</li>

																	<li>
																		<Link to="/auto-insurance/nationwide/">
																			<img src={Nationwide} alt="logo" />
          Nationwide
																		</Link>
																	</li>

																	{/* <li>
																		<Link to="/auto-insurance/safeco/">
																			<img src={Safeco} alt="logo" />
          Safeco
																		</Link>
																	</li> */}
																</ul>
															</Card.Body>
														</Accordion.Collapse>
													</Card>
												</div></div>
										</Accordion>
									</Card.Body>
								</Accordion.Collapse>

							</Card>
						</Accordion>

						<NavDropdown
							onClick={() => clicked( 3 )} title={
								<div className="d-flex justify-content-between align-items-center menu-heading">
                Tools
									<img src={showArrow == 3 ?  iconorange : iconback} alt="icon"/>
								</div>
							}
							id="collasible-nav-dropdown-2">

							<NavDropdown.Item className='menu-sub-heading commonpadding' rel="noreferrer noopener" target="_blank" href="https://app.getmybubble.com/lifescore/">Bubble LifeScore<sup><b className='supmenutxt'>TM</b></sup></NavDropdown.Item>

							<NavDropdown.Divider className="commonborderbt" />

							<NavDropdown.Item className='menu-sub-heading commonpadding commonheadlink' rel="noreferrer noopener" target="_blank" href="https://app.getmybubble.com/homescore/">HomeScore</NavDropdown.Item>


							<NavDropdown.Divider className="commonborderbt" />

							<NavDropdown.Item className='menu-sub-heading commonpadding' rel="noreferrer noopener" target="_blank" href="https://www.getmybubble.com/blog/partner-widgets/">Widgets</NavDropdown.Item>

							<NavDropdown.Divider />

							<NavDropdown.Item className='menu-sub-heading commonpadding cursor' data-bs-toggle="modal" data-bs-target="#lifepalmodal" rel="noopener noreferrer">LifePal<sup><b className='supmenutxt'>TM</b></sup></NavDropdown.Item>

							<NavDropdown.Divider />

							<NavDropdown.Item className='menu-sub-heading commonpadding cursor' data-bs-toggle="modal" data-bs-target="#homepalmodal" rel="noopener noreferrer">HomePal<sup><b className='supmenutxt'>TM</b></sup></NavDropdown.Item>

						</NavDropdown>

						<NavDropdown
							title={
								<div className="d-flex justify-content-between align-items-center menu-heading">
									<div  onClick={() => clicked( 20 )} className='x menu-heading bordernone d-flex justify-content-between align-items-center'>
										<Link style={{ color: showArrow != 20 ? '#151719' : '#ff7007' }}>Partner With Us
										</Link>

										<img src={showArrow == 20 ?  iconorange : iconback} alt="icon"/>
									</div>
								</div>
							} id="collasible-nav-dropdown">
							<NavDropdown.Item className="space0"
								// onClick={( e ) => { e.stopPropagation(); window.location.href = process.env.GATSBY_AGENT_PORTAL_URL + 'login'; }}
							>
								<div  className='partner-mobile'>
									<a href="/partnerwithus/">
                    Partner With Us
									</a>
								</div>

								<div className="loginbtn menu-heading">
									<a href={process.env.GATSBY_AGENT_PORTAL_URL + 'login'}>
										<img src={loginbtn} alt="login btn"/>Partner Login
									</a>
								</div>
							</NavDropdown.Item>
						</NavDropdown>
					</div>


					{/* <Nav.Link className="partnerlink">
            <Link className='menu-heading' to="/partnerwithus/">Partner With Us</Link>
          </Nav.Link> */}

					<NavDropdown
						onClick={() => clicked( 4 )}
						title={
							<div className="d-flex justify-content-between align-items-center menu-heading">
            Contact Us
								<img src={showArrow == 4 ?  iconorange : iconback} alt="icon"/>
							</div>
						} id="collasible-nav-dropdown-3"
					>
						<NavDropdown.Item>
							<a
								onClick={
									( e ) => {
										e.preventDefault();
										modalChanged();
									}}
								style={{ color: '#ff7007' }}
								className='learnMore commonheadlink'
							>
								<img src={giticon} className="" alt="phone"/> Get in Touch With Us
							</a>
						</NavDropdown.Item>

						<NavDropdown.Item href="tel:+18339002822">
							<a
								onClick={
									( e ) => {
										e.preventDefault();
										window.open( 'tel:+18339002822', '_blank' );
									}}
								style={{ color: '#ff7007' }}
								className='learnMore commonheadlink'
								to="tel:+18339002822">
								<img src={call1} alt="call" />
                (833) 900-BUBB
							</a>
						</NavDropdown.Item>

						{/* <NavDropdown.Divider /> */}

						<NavDropdown.Item href="mailto:hello@getmybubble.com">
							<a className="MobileLftPadding" href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">


								<p className="fwNormal"><img src={email1} alt="email" />hello@getmybubble.com</p>

								<span>(24/7, at your service)</span>
							</a>

							{/* <a className="MobileLftPadding" href="mailto:hello@getmybubble.com" target="_blank" rel="noopener noreferrer">
                <div className="learnMore fwNormal"> hello@getmybubble.com <p className="para" style={{ opacity: 1 }}> (24/7, at your service)</p></div>
              </a> */}

						</NavDropdown.Item>

						<NavDropdown.Divider />
					</NavDropdown>

					<Modal show={modalOpen} onHide={() => modalChanged( false )} className="hsformbox"  aria-labelledby="contained-modal-title-vcenter" center>

						<div className='modal-dialog modal-dialog-centered'>
							<Modal.Header closeButton>
								<Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
							</Modal.Header>

							<div id="hs-form">

								<Modal.Body>
									<HubSpotContactUsForm
										closeModal={() => modalChanged( false )}/>
								</Modal.Body>
							</div>
						</div>

					</Modal>


					{/* <button type="button" className='button-orange getquotebtn'>
            Get a Quote
          </button> */}

					<div className="modal fade" id="lifepalmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog  modal-dialog-centered  modal-lg" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div className="modal-body">
									<LifePal/>
								</div>
							</div>
						</div>
					</div>

					<div className="modal fade" id="homepalmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog  modal-dialog-centered modal-lg" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div className="modal-body">
									<HomePal/>
								</div>
							</div>
						</div>
					</div>

					{/* <div className="loginbtn menu-heading">
            <a href={process.env.GATSBY_AGENT_PORTAL_URL}><img src={loginbtn} alt="login btn"/>Agent Login</a>
          </div> */}
				</Nav>

			</Navbar.Collapse>
		</Navbar>
	);
}

export default MenuMobile;
